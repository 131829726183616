<template>
  <div
    v-if="!loading"
    id="app"
  >
    <router-view />
  </div>
</template>
<script>
import store from './store';
export default {
  data() {
    return {
      loading: true, // 保证持久化的数据能在页面展示之前准备好
    };
  },
  async created() {
    // 从持久化数据加载一系列的设置
    await store.dispatch('system/load');
    this.loading = false;
  },
  methods: {},
};
</script>
<style lang="scss">
@import url('~@/assets/style/public-class.scss');
</style>
